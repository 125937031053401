@use './config/' as *;
@forward "./main-scss/";
.hide-playercard {
    .card-list {
        @extend %d-none;
    }
}
.waf-statsdetail {
    --_thumbnail: 12.4rem;
    --_content: 4.8rem;
    .layout-wrapper {
        position: relative;
    }
    .head-wrap {
        @extend %flex-wrap;
        .title {
            @extend %neutral-50;
        }
    }
    .table {
        @extend %flex;
        @extend %full-radius;
        @extend %hidden;
        &-responsive {
            @extend %transparent-bg;
            .btn-more {
                @extend %my-6;
            }
            .table-data {
                @extend %w-100;
            }
        }
        &-head {
            min-width: unset;
        }
        &-left {
            width: 80%;
        }
        &-right {
            width: 20%;
        }
        &-body {
            @extend %secondary-1000;
        }
        &-data {
            border-left: .1rem solid clr(secondary-1000, 1);
            &.position {
                max-width: 5rem;
                flex-shrink: 0;
            }
            &.label {
                justify-content: flex-start;
                @extend %px-3;
                .image {
                    width: 4.5rem;
                    height: 4.5rem;
                    flex-shrink: 0;
                    border: .2rem solid clr(secondary-1000, 2);
                    @extend %mr-2;
                    @extend %p-1;
                    @extend %circle-radius;
                    @extend %neutral-50-bg;
                }
                .table-data-wrapper {
                    @extend %flex-n-c;
                    .name {
                        @extend %d-none;
                    }
                }
            }
            &-text {
                flex: 1;
                @extend %text-left;
            }
            &-wrapper {
                flex: 1;
            }
            &-name {
                width: max-content;
                border-bottom: .1rem solid clr(secondary-1000, 2);
                @extend %font-12-pb;
                @extend %pb-1;
                @extend %mb-1;
            }
            &-team {
                @extend %font-12-pm;
                @extend %neutral-1000-5;
            }
        }
        &-row {
            .runs {
                span {
                    font-weight: 700;
                }
            }
            &.highlight {
                @extend %primary-500-bg;
            }
        }
    }
    .card {
        &-head {
            @extend %flex;
            @extend %relative;
        }
        &-info {
            flex: 1;
            @extend %px-2;
            @extend %pt-2;
        }
        &-team {
            @extend %uppercase;
            @extend %font-10;
        }
        &-label {
            width: max-content;
            @extend %half-radius;
            @extend %p-1-2;
            @extend %mt-6;
            @extend %mb-2;
            @extend %font-12-pm;
            @extend %secondary-300-bg;
        }
        &-logo {
            position: absolute;
            top: 6.5rem;
            left: calc(var(--_thumbnail) + 1rem);
        }
        &-name {
            @extend %font-20-pr;
            @extend %uppercase;
            .name {
                display: block;
            }
            .lname {
                font-weight: 700;
            }
        }
        &-position {
            position: absolute;
            top: var(--space-1);
            left: var(--space-1);
            @extend %secondary-400;
            @extend %font-14-pb;
        }
        &-thumbnail {
            width: var(--_thumbnail);
            flex-shrink: 0;
            margin-bottom: var(--_content);
            @extend %px-4;
            @include background(null, "players/player-bg.png", bottom/cover, linear-gradient(180deg, clr(secondary-800) 0%, clr(secondary-1000) 100%));
            .image {
                height: 100%;
                object-fit: cover;
            }
        }
        &-item {
            border: .1rem solid clr(primary-500);
            @extend %neutral-50-bg;
            @extend %full-radius;
            @extend %hidden;
            @extend %secondary-1000;
            @extend %mb-4;
        }
        &-logo {
            .image {
                @extend %d-none;
            }
        }
        &-points {
            flex-direction: column-reverse;
            flex: 1;
            border-right: .1rem solid clr(neutral-1000, 1);
            @extend %gap-1;
            @extend %text-center;
            @extend %flex-c-c;
            @extend %uppercase;
            .number {
                @extend %font-16-pb;
            }
            .label {
                @extend %font-10-pm;
                @extend %neutral-600;
            }
            &-list {
                box-shadow: 0 0 1rem 0 clr(neutral-1000, 2);
                height: var(--_content);
                margin-left: calc((var(--_thumbnail) + 1rem) * -1);
                @extend %mr-2-neg;
                @extend %neutral-50-bg;
                @extend %flex;
            }
        }
    }
    .btn-more {
        width: max-content;
        display: block;
        @extend %uppercase;
        @extend %quarter-radius;
        @extend %primary-500-bg;
        @extend %secondary-1000;
        @extend %p-1-2;
        @extend %mx-auto;
        @extend %my-0;
        @extend %font-12-pm;
    }
}
.waf-statsdetail {
    .tab-title {
        display: none;
    }
    .tab-text {
        width: 100%;
    }
}
.waf-ad-page-top {
    padding-block: 2rem;
}
@include mq(col-md) {
    .waf-statsdetail {
        --_thumbnail: 18.8rem;
        --_content: 8.1rem;
        .table {
            margin-top: var(--space-4);
            &-left {
                width: 50%;
            }
            &-right {
                width: 50%;
            }
            &-data {
                min-width: 6rem;
                width: 100%;
                flex-shrink: unset;
                &.label {
                    width: 100%;
                }
                &.position {
                    max-width: unset;
                    width: 8rem;
                    // flex-shrink: 0;
                }
                &-name {
                    font-size: 1.4rem;
                }
            }
            &-body {
                .table-data:not(.runs, .position) {
                    .text {
                        font-weight: 400;
                    }
                }
            }
        }
        .card {
            &-points {
                &-list {
                    margin: var(--space-4) var(--space-4-neg) var(--space-4-neg);
                    box-shadow: none;
                    border-top: .1rem solid clr(secondary-1000, 2);
                }
                .number {
                    font-size: 2.2rem;
                    font-weight: 500;
                }
                .label {
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
            &-thumbnail {
                margin-bottom: 0;
                display: flex;
                align-items: flex-end;
            }
            &-info {
                padding: var(--space-4);
            }
            &-position {
                font-size: 2.2rem;
                box-shadow: unset;
            }
            &-name {
                font-size: 2.4rem;
            }
            &-logo {
                top: 8rem;
                left: calc(var(--_thumbnail) + 2rem);
            }
            &-team {
                font-size: 1.2rem;
            }
        }
    }
    .waf-ad-page-top {
        padding-block: 2rem;
    }
}
@include mq(col-lg) {
    .waf-statsdetail {
        .table {
            &-left {
                width: 40%;
            }
            &-right {
                width: 60%;
            }
        }
    }
}